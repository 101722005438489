<template>
  <div style='position: relative;' class='otp-input'>

    <input type='hidden' :value='value' :name='name'>

    <div class='inputer'>
      <input :id='id' type='text' minlength="16" placeholder='Enter text-based OTP secret key' :value="value" @keydown.space.prevent @input="format($event)">
      <aside>
        <a href='#' @click.prevent.stop="clear"><i class='fas fa-trash icon'></i>Clear</a>
      </aside>
    </div>
    <p class='otp-input__help'><a href='https://support.usehudu.com/hc/en-us/articles/360052291793' target='_blank'>Instructions</a></p>
  </div>
</template>

<script>
export default {
  props: ["name", "prevalue", "id"],
  methods: {
    choose (id, name) {
      this.chosen = {id: id, name}
      this.isShown = false;
    },
    format (e) {
      this.value = e.target.value.toUpperCase().replace(/\s/g, '');;
    },
    clear () {
      this.value = ""
    }
  },
  data () {
    return {
      isShown: false,
      value: this.prevalue
    }
  }
}
</script>

