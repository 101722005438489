<template>
  
  <div>
    <flat-pickr
                v-model="date"
                :config="config"                                                          
                class="form-control" 
                placeholder="Select date"               
                name="date"></flat-pickr>
    <input type='hidden' :id='id' :value="date" :name="name">
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';  
export default {
  props: ["name", "id", "value", "day"],
  components: {
    flatPickr
  },    
  data () {
    return {
      date: this.value,
      config: {
        locale: {
          firstDayOfWeek: this.day
        },
        wrap: false,
        allowInput: true,
        dateFormat: 'Y-m-d'    
      },       
    }        
  }
}
</script>

