<template>
  
  <div>
    <a @click.prevent.stop="switchToggle" data-tooltip="Refresh Integrations" :class='{"tooltip-right header__a header__a--knowledge-base": true, "active": isShown}'><i class='fas fa-sync'></i></a>
    <div class='vault__box' v-if='isShown'>
      <header>
        <h2>Refresh Integrations</h2>
        <div class='button-group'>

          <a href='#' class='close' @click.prevent.stop="toggle(0)"><i class='fas fa-times'></i></a>
        </div>
      </header>

      <slot v-if='isShown'></slot>
    </div>
    
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters("globals", {
      'isShown': 'showSyncBar'
    })
  },
  methods: {
    switchToggle() {
      if (this.isShown) {
        this.toggle(0)
      } else {
        this.toggle(2)
      }
    },
    toggle(newHeader) {
      this.$store.commit('globals/changeHeader', newHeader)
    },
  }
}
</script>

