<template>
  <div v-if='data.length > 0'>
    <div class='company-sidebar__title'>
      <h2>Custom Assets</h2>
      <a href='#' v-if='!hideEmpty' @click.prevent.stop='toggleEmpty'><i class='fas fa-eye-slash'></i> Hide Empty</a>
      <a href='#' v-if='hideEmpty' @click.prevent.stop='toggleEmpty'><i class='fas fa-eye'></i> Show Empty</a>
    </div>
    <a :href="d.url" v-for='(d, index) in filteredData' :class='{"selected": d.selected}'>
      <div class="asset-icon" :style="{background: d.color}">
        <i :class="d.icon" :style="{color: d.icon_color}"></i>
      </div>
      <span class="company-sidebar__name">{{d.name}}</span>
      <span class="count">{{d.count}}</span>
    </a>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data () {
    return {
      hideEmpty: false
    }        
  },
  mounted () {
    if (localStorage.getItem('huduHideEmptySidebarAssets')) {
      try {
        this.hideEmpty = true;
      } catch(e) {
        
      }
    }
  },
  methods: {
    toggleEmpty () {
      this.hideEmpty = !this.hideEmpty;

      if (this.hideEmpty === true) {
        localStorage.hide_assets = true
        localStorage.setItem('huduHideEmptySidebarAssets', true);
      } else {
        localStorage.removeItem('huduHideEmptySidebarAssets')
      }
      
      // if (this.hideEmpty === true) {
        
      //   localStorage.setItem('huduHideEmptySidebarAssets', false);
      // } else {
      //   this.hideEmpty = true;
      //   localStorage.setItem('huduHideEmptySidebarAssets', true);
      // }
    }
  },
  computed: {
    filteredData() {
      return this.hideEmpty ? this.data.filter(item => !(item.count == 0)) : this.data
    }
  }
}
</script>