<template>
  <div class='writer-wrap'>
    <div :class='{"writer": true, "writer--full": isFull}'>
      <editor-menu-bar :editor="editor" v-slot="{ commands, isActive, getMarkAttrs }">
        <div class="menubar">
          <div class="menubar__toolbar">
            <section>
              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.bold() }"
                @click.prevent.stop="commands.bold"
              >
                <i class="fas fa-bold"></i>
              </button>

              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.italic() }"
                @click.prevent.stop="commands.italic"
              >
                <i class="fas fa-italic"></i>
              </button>

              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.strike() }"
                @click.prevent.stop="commands.strike"
              >
                <i class="fas fa-strikethrough"></i>
              </button>
          </section>

            <section v-if='!simple'>
              <button
                v-show='isActive.heading()' class="menubar__button"
                :class="{ 'is-active': isActive.paragraph() }"
                @click.prevent.stop="commands.paragraph"
              >
                <span class='writer__m'>P</span>
              </button>
              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                @click.prevent.stop="commands.heading({ level: 1 })"
              >
                <span class='writer__m'>H1</span>
              </button>

              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                @click.prevent.stop="commands.heading({ level: 2 })"
              >
                <span class='writer__m'>H2</span>
              </button>

              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                @click.prevent.stop="commands.heading({ level: 3 })"
              >
                <span class='writer__m'>H3</span>
              </button>
            </section>
            <section>
              <button class="menubar__button" @click.prevent.stop="openModal(commands.image);">
                <i class="far fa-image"></i>
              </button>
              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.bullet_list() }"
                @click.prevent.stop="commands.bullet_list"
              >
                <i class="fas fa-list-ul"></i>
              </button>

              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.ordered_list() }"
                @click.prevent.stop="commands.ordered_list"
              >
                <i class="fas fa-list-ol"></i>
              </button>
              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.code_block() }"
                @click.prevent.stop="commands.code_block"
              >
               <i class="fas fa-code"></i>
              </button>

              <button
                class="menubar__button"
                @click.prevent.stop="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })"
              >
                <i class="fas fa-table"></i>
              </button>
              <button class="menubar__button" @click.prevent.stop="showLinkMenu(getMarkAttrs('link'))" :class="{ 'is-active': isActive.link() }">
                <i class='fas fa-link'></i>
                </button>
              </section>
            

            <section class='full'>
              <button v-if='!isFull' class="menubar__button menubar__button--primary" @click.prevent.stop="isFull = true">
                <i class="fas fa-expand"></i>
              </button>
              <button v-if='isFull' class="is-active menubar__button menubar__button--primary" @click.prevent.stop="isFull = false">
                <i class="fas fa-expand"></i>
              </button>
            </section>
          </div>

          <writer-modal ref="ytmodal" @onConfirm="addCommand" />
          <div class='menubar__subtoolbar' v-if="linkMenuIsActive">

            <form class="menubar__form" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
              <h3 class='menubar__header'>Highlight text before adding a link</h3>
              <input class="menubar__input" type="text" v-model="linkUrl" placeholder="enter URL and hit enter..." ref="linkInput" @keydown.esc="hideLinkMenu"/>
              <button class="button button--secondary" @click="setLinkUrl(commands.link, null)" type="button">
                <span>Close</span>
              </button>
            </form>
          </div>
          <div class='menubar__subtoolbar' v-if="isActive.table()">
              <button
                class="menubar__button"
                @click.prevent.stop="commands.deleteTable">
                Delete Table
              </button>
              <button
                class="menubar__button"
                @click.prevent.stop="commands.addColumnBefore"
              >
                Add Column Before
              </button>
              <button
                class="menubar__button"
                @click.prevent.stop="commands.addColumnAfter"
              >
                Add Column After
              </button>
              <button
                class="menubar__button"
                @click.prevent.stop="commands.deleteColumn"
              >
                Delete Column
              </button>
              <button
                class="menubar__button"
                @click.prevent.stop="commands.addRowBefore"
              >
                Add Row Before
              </button>
              <button
                class="menubar__button"
                @click.prevent.stop="commands.addRowAfter"
              >
               Add Row After
              </button>
              <button
                class="menubar__button"
                @click.prevent.stop="commands.deleteRow"
              >
                Delete Row
              </button>
              <button
                class="menubar__button"
                @click.prevent.stop="commands.toggleCellMerge"
              >
                Cell Merge
              </button>
              
            </div>
        </div>
      </editor-menu-bar>
      <input type="text" style='display: none' v-model="editor.extensions.options.placeholder.emptyNodeText">
      <editor-content class="writer__content" :editor="editor" />
    </div>
  </div>
</template>

<script>
import WriterIcon from './writer_icon.vue'
import WriterModal from "./writer_modal.vue";
import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble} from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  Placeholder,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Table,
  TableHeader,
  TableCell,
  TableRow,
  Strike,
  Underline,
  History,
  TrailingNode,

} from 'tiptap-extensions'
import { mapState } from 'vuex'
import Image from '../lib/Image.js';

export default {
  props: ["index"],
  components: {
    EditorContent,
    EditorMenuBar,
    WriterIcon,
    WriterModal,
    EditorMenuBubble
  },
  computed: {
    description () {
      if (this.index != null) {
        return this.$store.state.procedures.procedure.procedure_tasks_attributes[this.index].description
      } else {
        return this.$store.state.procedures.procedure.description
      }
    }, 
  },
  methods: {
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu() {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl(command, url) {
      command({ href: url })
      this.hideLinkMenu()
    },
    openModal(command) {
      this.$refs.ytmodal.showModal(command);
    },
    addCommand(data) {
      if (data.command !== null) {
        data.command(data.data);
      }
    },
  },
  mounted () {
    this.editor.setContent(this.description)
  },
  watch: {
    description (newValue) {
      if (this.editor && val !== this.description) {
        this.editor.setContent(this.description)
      }
    }
  },
  data() {
    return {
      isFull: false,
      linkUrl: null,
      linkMenuIsActive: false,
      showSource: false,
      html: "",
      editor: new Editor({
        extensions: [
          new HardBreak(),
          new Blockquote(),
          new Image(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          // new TrailingNode({
          //   node: 'paragraph',
          //   notAfter: ['paragraph'],
          // }),
          new Strike(),
          new Underline(),
          new History(),
          new Table({
            resizable: true,
          }),
          new TableHeader(),
          new TableCell(),
          new TableRow(),
          new Placeholder({
            emptyEditorClass: 'is-editor-empty',
            emptyNodeClass: 'is-empty',
            emptyNodeText: 'Write something...',
            showOnlyWhenEditable: true,
            showOnlyCurrent: true,
          }),
        ],

        content: this.description,
        onUpdate: ({ getJSON, getHTML }) => {
          var that = this;
          var content = getHTML();
          
          if (that.index === undefined) {
            if (content != "<p></p>") {
              this.$store.dispatch('procedures/updateProcedureDescription', content)
            } else {
              this.$store.dispatch('procedures/updateProcedureDescription', "")
            }
          } else {
            if (content != "<p></p>") {
              this.$store.dispatch('procedures/updateTaskDescription', [that.index, content])
            } else {
              this.$store.dispatch('procedures/updateTaskDescription', [that.index, ""])
            }
          }
        },
      }),
    }
  },
  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>

<style lang='scss'>
p.is-editor-empty:first-child::before {
  content: attr(data-empty-text);
  float: left;
  color: #aaa;
  pointer-events: none;
  height: 0;
}

</style>

