<template>
  <div class='vault'>
    <flash-message></flash-message>
    <a data-tooltip="Personal Passwords" @click.prevent.stop="switchToggle" :class="{'tooltip-right': true,  'header__a': true, 'active': isShown}" href='#'><i class="fas fa-key"></i></a>

    <div class='vault__box' v-if='isShown'>
      <header>
        <h2>Personal Passwords</h2>
        <div class='button-group'>
          <a :href='new_url' class='button button--small button--success'><i class='fas fa-plus'></i>New</a>
          <a href='#' class='close' @click.prevent.stop="toggle(0)"><i class='fas fa-times'></i></a>
        </div>
      </header>
      <div class='search'>
        <icon></icon>
        <input ref="searchInput" type='text'
          v-debounce:100ms="onInput"
          v-model='q' placeholder="Search passwords...">
      </div>

      <div class='vault__item' v-for='result in results'>
        <article>
          <h3><a :href='result.hudu_url'>{{result.name}}</a></h3>
          <p v-if='result.url'>{{result.url}}</p>
        </article>
        <div class='button-group'>
          <a href='#' v-if='result.username' @click.prevent.stop="copyUsername(result.username)" title='Copy Username' class='button button--small button--secondary'><i class='fas fa-user'></i></a>
          <a href='#' @click.prevent.stop="copyPassword(result.id, result.fetch_password_url)" title='Copy Password' class='button button--small button--secondary'><i class='fas fa-key'></i></a>
        </div>
      </div>

      <div class='vault__blank' v-if='results.length == 0'>No results found.</div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import Icon from "../components/icon.vue";
import { mapGetters } from 'vuex'

export default {
  name: 'vault',
  props: ["new_url", "active", "index_url", "closeHeader"],
  components: {
    Icon
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters("globals", {
      'isShown': 'showVault'
    })
  },
  mounted () {
    var that = this;
    axios({
      url: that.index_url,
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })
    .then(function (response) {
      that.results = response.data;
    })
  },
  methods: {
    copyUsername (username) {
      var that = this;

      Clipboard.copy(username);
      that.isCopied = true;

      that.flash("Copied Username!", 'success', 
        {
          timeout: 3000,
          important: true
        }
      );
    },
    copyPassword (id, fetch_password_url) {
      var that = this;

      axios({
        url: fetch_password_url,
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      })
      .then(function (response) {
        var password = response.data.password;

        if (password) {
          Clipboard.copy(password);
          that.isCopied = true;

          that.flash("Copied Password!", 'success', 
            {
              timeout: 3000,
              important: true
            }
          );
        }
      })

      
    },
    onInput () {
      var that = this;

      axios({
        url: that.index_url,
        method: "GET",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        params: {
          q: that.q
        }
      })
      .then(function (response) {
        that.results = response.data;
      })
    },
    toggle(newHeader) {
      this.$store.commit('globals/changeHeader', newHeader)
    },
    switchToggle() {
      if (this.isShown) {
        this.toggle(0)
      } else {
        this.toggle(1)
        this.$nextTick(() => this.$refs['searchInput'].focus())
      }
    }
  },
  data: function () {
    return {
      q: "",
      results: []
    }
  }
}
window.Clipboard = (function(window, document, navigator) {
    var textArea,
        copy;

    function isOS() {
        return navigator.userAgent.match(/ipad|iphone/i);
    }

    function createTextArea(text) {
        textArea = document.createElement('textArea');
        textArea.value = text;
        document.body.appendChild(textArea);
    }

    function selectText() {
        var range,
            selection;

        if (isOS()) {
            range = document.createRange();
            range.selectNodeContents(textArea);
            selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
            textArea.setSelectionRange(0, 999999);
        } else {
            textArea.select();
        }
    }

    function copyToClipboard() {        
        document.execCommand('copy');
        document.body.removeChild(textArea);
    }

    copy = function(text) {
        createTextArea(text);
        selectText();
        copyToClipboard();
    };

    return {
        copy: copy
    };
})(window, document, navigator);
</script>

