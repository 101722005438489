<template>
  
<div>

  <nav class='header__nav'>
    <a href='#' v-if='show_menu' id='triggerHeader' class='header__button'><i class='fas fa-bars'></i>MENU</a>

    <a data-tooltip="Dashboard" 
      :class='{"tooltip-right header__a header__a--home": true, "active": active == 1}' :href='dashboards_path'><i class='fas fa-home'></i></a>

    <a data-tooltip="Companies" 
    :class='{"tooltip-right header__a header__a--companies": true, "active": active == 2 }'
    :href='companies_path'><i class='fas fa-briefcase'></i></a>

    <a data-tooltip="Global Knowledge Base" 
    :class='{"tooltip-right header__a header__a--knowledge-base": true, "active": active == 3}' :href='articles_path'><i class='fas fa-newspaper'></i></a>

    <a data-tooltip="Global Process Templates" 
    :class='{"tooltip-right header__a header__a--process-templates": true, "active": active == 4}' :href='process_templates_path'><i class='fas fa-check'></i></a>

    <vault v-if='!remove_vault' :new_url="new_vault_password_path" :index_url="vault_passwords_path"></vault>

    <slot></slot>

    <a v-if='is_admin' data-tooltip="Admin" :class='{"tooltip-right header__a header__a--admin": true, "active": active == 7}' :href='admin_path'><i class='fas fa-cog'></i></a>
  </nav>

</div>

</template>

<script>

import Vault from "./vault";
import { mapGetters } from 'vuex'

export default {
  props: [
    "tab", 
    "show_menu",
    "remove_vault", 
    "dashboards_path",
    "companies_path",
    "process_templates_path",
    "new_vault_password_path",
    "vault_passwords_path",
    "articles_path",
    "admin_path",
    "is_admin"
    ],
  mounted () {
    
  },
  computed: {
    ...mapGetters("globals", {
      'showVault': 'showVault',
      'showSyncBar': 'showSyncBar'
    }),
    active () {
      if (this.showVault || this.showSyncBar) {
        return 0
      } else if (this.tab == "dashboard") {
        return 1
      } else if (this.tab == "companies") {
        return 2
      } else if (this.tab == "articles") {
        return 3
      } else if (this.tab == "process_templates") {
        return 4
      } else if (this.tab == "admin") {
        return 7
      }
    }
  },
  components: {
    Vault
  },
  methods: {
  },
  data () {
    return {
      isShown: false
    }
  }
}
</script>

