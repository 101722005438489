<template>
  <div @keydown.esc="isShown = false">
    <a href='#' class='fast-facts' @click.prevent.stop='isShown = true'><i class='fas fa-bolt'></i>Fast Facts</a>
    <div class='fast-facts__wrapper' v-if='isShown'>
      <div class='fast-facts__container' v-click-outside="onClickOutside">
        <header class='fast-facts__header'>
          <h1><i class='fas fa-bolt icon'></i>Fast Facts about {{company_name}}</h1>
          <a href='#' class='close' @click.prevent.stop='isShown = false'><i class='fas fa-times'></i></a>
        </header>
        <slot>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
  props: ["company_name"],
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    onClickOutside (event) {
      this.isShown = false
    },
  },
  data () {
    return {
      isShown: false
    }
  }
}
</script>